import React, { useEffect } from 'react'
import { useHistory, Switch, Route, Redirect } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { useIdleTimer } from 'react-idle-timer'

import Login from '@Pages/Login'
import FirstLogin from '@Pages/FirstLogin'
import Admin from '@Pages/admin/Admin'
import ExpertHome from '@Pages/expert/ExpertHome'
import CustomerHome from '@Pages/customer/CustomerHome'
import CheckFolder from '@Pages/CheckFolder'

import QSnackbar from '@Components/QSnackbar'
import Downloader from '@Components/Downloader'
import { useAuth } from '@Context/auth'

const LOGOUT_IDLE = 30 * 60000 // 30min

function App() {
  const { isReady, isLogged, user, logout } = useAuth()
  const isAdmin = user?.role?.endsWith('Admin') || false
  const isExpert = user?.role === 'Expert'
  const history = useHistory()
  useEffect(() => {
    const loadSW = () => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/sw.js')
      }
    }

    window.addEventListener('load', loadSW)

    return () => window.removeEventListener('load', loadSW)
  }, [])

  useEffect(() => {
    if (history.location.pathname === '/messages') {
      document.body.style.overflow = 'hidden'
    }
    return history.listen((location) => {
      if (location.pathname === '/messages') {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    })
  }, [history])

  const idleTimer = useIdleTimer({
    timeout: LOGOUT_IDLE,
    debounce: 500,
    crossTab: { emitOnAllTabs: true },
    onIdle: logout,
    onAction: () => {
      window.localStorage.setItem(
        'lastActiveTime',
        idleTimer.getLastActiveTime()
      )
    },
  })

  useEffect(() => {
    // idleTimer is only counting when the app is running
    // this is to force logout when the user go back to the app
    if (isLogged) {
      const lastActiveTime = parseInt(
        window.localStorage.getItem('lastActiveTime') || 0
      )
      const limit = +new Date() - LOGOUT_IDLE

      if (lastActiveTime > 0 && limit > lastActiveTime) {
        logout()
      }
    }
  }, [isLogged, idleTimer])

  return (
    <>
      <QSnackbar />

      {isReady && (
        <AnimatePresence exitBeforeEnter>
          <Switch>
            <Route
              path="/login"
              render={() => {
                if (isLogged) return <Redirect to={{ pathname: '/' }} />
                return <Login />
              }}
            />

            <Route
              path="/first-login/:token"
              render={() => {
                if (isLogged) return <Redirect to={{ pathname: '/' }} />
                return <FirstLogin />
              }}
            />

            <Route path="/check-folder/:token">
              <CheckFolder />
            </Route>

            <Route path="/download/:docId">
              <Downloader />
            </Route>

            <Route
              path="/admin"
              render={() => {
                if (!isLogged) return <Redirect to={{ pathname: '/login' }} />
                if (!isAdmin) return <Redirect to={{ pathname: '/' }} />
                return <Admin />
              }}
            />

            <Route
              path="/"
              render={() => {
                if (!isLogged) return <Redirect to={{ pathname: '/login' }} />
                if (isAdmin)
                  return <Redirect to={{ pathname: '/admin/folders' }} />
                if (isExpert) return <ExpertHome />
                return <CustomerHome />
              }}
            />
          </Switch>
        </AnimatePresence>
      )}
    </>
  )
}

export default App
