import React, { useState } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { Box } from '@material-ui/core'
import Menu from '@CommonComponents/Menu'
import classnames from 'classnames'
import arrowCollapse from '@Assets/img/arrow-menu.svg'
import StepType from '@Pages/admin/parameters/StepType'
import StepTypeList from '@Pages/admin/parameters/StepTypeList'
import UsefulInformation from '@Pages/admin/parameters/UsefulInformation'
import QuoteWriting from '@Pages/admin/parameters/QuoteWriting'
import SelectionTypeManagement from '@Pages/admin/parameters/SelectionTypeManagement'
import DomainsManagement from '@Pages/admin/parameters/DomainsManagement'
import Chronology from '@Pages/admin/parameters/Chronology'
import ChronologyList from '@Pages/admin/parameters/ChronologyList'

export default function Parameters() {
  const location = useLocation()
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false)

  const paramsSubMenu = [
    {
      title: "Domaines d'activité",
      linkTo: '/admin/parameters/domains',
    },
    {
      title: 'Chronologies',
      linkTo: '/admin/parameters/chrono',
    },
    {
      title: "Types d'étapes",
      linkTo: '/admin/parameters/step-types',
    },
    {
      title: 'Types de selections notes',
      linkTo: '/admin/parameters/notes-categories',
    },
    {
      title: 'Informations pratiques',
      linkTo: '/admin/parameters/useful-information',
    },
    {
      title: 'Explication rédaction devis',
      linkTo: '/admin/parameters/quote-writing',
    },
  ]

  return (
    <AnimatePresence exitBeforeEnter>
      <Box className="Parameters">
        <Box
          className={classnames('Parameters-menu', {
            isCollapsed: isMenuCollapsed,
          })}
        >
          <Menu
            className="Parameters-nav"
            itemStyle="Parameters-menuItem"
            items={paramsSubMenu}
          />
          <button
            className="Parameters-collapseBtn"
            onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}
          >
            <img src={arrowCollapse} alt="Collapse" />
          </button>
        </Box>
        <Box
          className={classnames('Parameters-body page-container', {
            isCollapsed: isMenuCollapsed,
          })}
        >
          <Switch key={location.pathname} location={location}>
            <Route path="/admin/parameters/step-types/new">
              <StepType />
            </Route>

            <Route path="/admin/parameters/step-types/:stepTypeId">
              <StepType />
            </Route>

            <Route path="/admin/parameters/step-types">
              <StepTypeList />
            </Route>

            <Route path="/admin/parameters/useful-information">
              <UsefulInformation />
            </Route>

            <Route path="/admin/parameters/quote-writing">
              <QuoteWriting />
            </Route>

            <Route path="/admin/parameters/notes-categories">
              <SelectionTypeManagement />
            </Route>

            <Route path="/admin/parameters/domains">
              <DomainsManagement />
            </Route>

            <Route path="/admin/parameters/chrono/:chronoId/schema">
              <Chronology />
            </Route>

            <Route path="/admin/parameters/chrono">
              <ChronologyList />
            </Route>
          </Switch>
        </Box>
      </Box>
    </AnimatePresence>
  )
}
