import React, { useEffect, useState } from 'react'
import { differenceInDays, format } from 'date-fns'
import { AccountCircle as AccountCircleIcon } from '@material-ui/icons'
import { useAuth } from '@Context/auth.js'

export default function ChatMessage({
  content,
  createdBy,
  createdAt,
  mode,
  folder,
}) {
  const { user } = useAuth()
  const [className, setClassName] = useState()
  const [author, setAuthor] = useState()

  useEffect(() => {
    if (mode === 'chat' && user?.id === createdBy?.id) {
      let userRole = user.role

      if (userRole === 'Expert') {
        if (folder?.referent.id === user.id) {
          userRole = 'Avocat référent'
        } else if (
          folder?.experts.find((e) => e.id === user.id) !== undefined
        ) {
          userRole = 'Avocat expert'
        } else {
          userRole = 'Avocat'
        }
      }

      setAuthor(`Vous, ${userRole}`)
      setClassName('chat-message chat-message-me')
    } else {
      setClassName('chat-message')
      setAuthor(`${createdBy?.lastName} ${createdBy?.firstName}, Service Qualy`)
    }
  }, [className, user, createdBy, mode])

  return (
    <div className={className}>
      <div className="chat-message-head">
        <div className="text-bold">{author}</div>
        {createdAt && (
          <div className="text-smaller">
            {differenceInDays(new Date(), new Date(createdAt)) === 0 && (
              <div>{format(new Date(createdAt), 'HH:mm')}</div>
            )}
            {differenceInDays(new Date(), new Date(createdAt)) === 1 && (
              <div>Hier</div>
            )}
            {differenceInDays(new Date(), new Date(createdAt)) > 1 && (
              <div>{format(new Date(createdAt), 'dd/MM/yy')}</div>
            )}
          </div>
        )}
      </div>
      <div className="chat-message-body">{content}</div>
      {createdBy?.image ? (
        <div
          className="chat-avatar"
          style={{ backgroundImage: `url(${createdBy.image})` }}
        />
      ) : (
        <AccountCircleIcon />
      )}
    </div>
  )
}
