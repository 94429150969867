import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import DiscussionForm from '@Components/DiscussionForm'
import DiscussionItem from '@Components/DiscussionItem'
import InfiniteScroll from '@Components/InfiniteScroll'
import { compareAsc } from 'date-fns'
import Chat from '@Components/Chat'
import { getDiscussions } from '@API/messages'
import { upperFirst } from 'lodash'
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons'
import { DateTime } from 'luxon'
import { useMatchMedia } from '@Utils/hooks'

export default function Discussion({ apiMode, permissions }) {
  const [title, setTitle] = useState()
  const [currentItem, setCurrentItem] = useState()
  const [items, setItems] = useState([])
  const [searchQuery, setSearchQuery] = useState(null)
  const [page, setPage] = useState(1)
  const [totalItems, setTotalItems] = useState()
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [show, setShow] = useState(false)
  const [slideTo, setSlideTo] = useState(null)
  const PER_PAGE = 10
  const history = useHistory()

  const { search } = useLocation()

  const isMobile = useMatchMedia('(max-width: 700px)')

  const [folderId, setFolderId] = useState(() => {
    const param = new URLSearchParams(search).get('folder')
    return param ? parseInt(param) : null
  })

  useEffect(() => {
    if (loading || items.length === 0 || folderId === null) {
      return
    }

    setFolderId(null)

    const selectedItemIdx = items.findIndex((i) => i.folder?.id === folderId)

    if (selectedItemIdx !== -1) {
      selectItem(items[selectedItemIdx])
      setSlideTo(selectedItemIdx)
    }
  }, [search, items, loading, folderId])

  useEffect(() => {
    if (PER_PAGE * page < totalItems) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }
  }, [page, totalItems])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const response = await getDiscussions(apiMode, { search: searchQuery })
      setPage(1)
      if (response.data.results.length > 0) {
        const newItems = sortItems(response.data.results)
        const cItem = newItems[0]
        cItem.nbMessagesNotSeen = 0
        setItems(
          sortItems([...newItems.filter((i) => i.id !== cItem.id), cItem])
        )

        if (!isMobile) {
          setCurrentItem(cItem)
        }
      }
      setTotalItems(response.data.meta.total_items)
      setLoading(false)
    }
    fetchData()
  }, [searchQuery])

  useEffect(() => {
    async function fetchDiscussions(fetchPage) {
      const response = await getDiscussions(apiMode, {
        search: searchQuery,
        page: fetchPage,
      })
      const newItems = response.data.results

      setItems((currentItems) => {
        const currentItemsIds = currentItems.map((i) => i.id)
        const totalyNewItemsCount = newItems.filter(
          (i) => !currentItemsIds.includes(i.id)
        ).length

        const newItemsIds = newItems.map((i) => i.id)

        if (totalyNewItemsCount === 10) {
          fetchDiscussions(fetchPage + 1)
        }

        return sortItems([
          ...currentItems.filter((i) => !newItemsIds.includes(i.id)),
          ...newItems,
        ])
      })

      setTotalItems(response.data.meta.total_items)

      if (currentItem) {
        const updatedCurrentItem = newItems.find(
          (item) => item.id === currentItem.id
        )

        if (updatedCurrentItem) {
          selectItem(updatedCurrentItem)
        }
      }
    }

    const intervalId = setInterval(async () => {
      fetchDiscussions(1)
    }, 10000)

    return () => clearInterval(intervalId)
  }, [currentItem])

  useEffect(() => {
    if (currentItem) {
      let title
      if (['collaboration avocats et client'].includes(currentItem.type)) {
        title = currentItem?.folder?.name
      } else {
        title = `Service client: ${upperFirst(
          currentItem?.user?.civility
        )}. ${upperFirst(currentItem?.user?.lastName)} ${upperFirst(
          currentItem?.user?.firstName
        )}`
      }
      setTitle(title)
    }
  }, [title, currentItem])

  const selectItem = (item) => {
    if (item !== null) {
      item.nbMessagesNotSeen = 0
      setItems((currentItems) =>
        sortItems([...currentItems.filter((i) => i.id !== item.id), item])
      )
      setShow(true)
    } else {
      setShow(false)
    }

    setCurrentItem(item)
  }

  const searchItems = (searchQuery) => {
    setSearchQuery(searchQuery)
  }

  const loadItems = async (currentPage) => {
    setLoading(true)
    const response = await getDiscussions(apiMode, { page: currentPage })
    setItems((current) => sortItems([...current, ...response.data.results]))
    setLoading(false)
  }

  return (
    <div className="discussions-wrapper">
      <div>{folderId}</div>
      <div className="discussions-sidebar">
        <DiscussionForm onSearch={searchItems} />
        <div className="discussions-list">
          {items.length > 0 && (
            <InfiniteScroll
              loading={loading}
              inverse={false}
              hasMore={hasMore}
              fetch={loadItems}
              spaceBetween={0}
              slideTo={slideTo}
              page={page}
              setPage={setPage}
            >
              {items?.map((item) => (
                <DiscussionItem
                  key={item.id}
                  onClick={() => selectItem(item)}
                  discussion={item}
                  current={item.id === currentItem?.id}
                  read={item.userDiscussions.some((ud) => {
                    return (
                      compareAsc(
                        new Date(item.lastMessage.createdAt),
                        new Date(ud.seenAt)
                      ) > 0
                    )
                  })}
                />
              ))}
            </InfiniteScroll>
          )}
          {items.length === 0 && (
            <div className="discussions-empty">Aucune discussion</div>
          )}
        </div>
      </div>
      {currentItem && (
        <div
          className={
            show
              ? 'discussions-content discussions-content-visible'
              : 'discussions-content'
          }
        >
          <button
            className="discussions-content-back"
            onClick={() => {
              const folder = new URLSearchParams(search).get('folder')
              if (folder) {
                history.push('/folders/' + folder + '/chronology')
              } else {
                setShow(false)
                selectItem(null)
              }
            }}
          >
            <ArrowBackIcon />
          </button>
          <button
            className="discussions-content-back-history"
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
          </button>
          <Chat
            title={title}
            discussion={currentItem}
            type="discussion"
            mode="chat"
            apiMode={apiMode}
            permissions={
              currentItem.type === 'collaboration avocats et client'
                ? 'rw'
                : permissions
            }
          />
        </div>
      )}
    </div>
  )
}

function sortItems(items) {
  return items.sort((a, b) => {
    if (!a.updatedAt && !b.updatedAt) {
      return 0
    }

    if (!a.updatedAt) {
      return 1
    }

    if (!b.updatedAt) {
      return -1
    }

    return DateTime.fromISO(a.updatedAt) >= DateTime.fromISO(b.updatedAt)
      ? -1
      : 1
  })
}
