import React, { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import { motion, AnimatePresence } from 'framer-motion'
import { InputBase, FormControl } from '@material-ui/core'
import ChronoTimeline from '@Components/ChronoTimeline'
import ChronologiesAPI from '@API/admin/parameters/chronologies'
import { useForm } from 'react-hook-form'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import QSelect from '@CommonComponents/QSelect'
import { useSnackbar } from '@Context/snackbar'
import { getErrorMsgFromSymfonyResponse } from '@Helpers'
import QMuiButtonConfirmation from '@CommonComponents/Mui/QMuiButtonConfirmation'
import QMuiTimePicker from '@CommonComponents/Mui/QMuiTimePicker'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'
import StepTypeAddForm from '@Components/forms/StepTypeAddForm'
import formRules from '@Utils/form-rules'
import classnames from 'classnames'
import {
  ArrowBack as ArrowBackIcon,
  Add as AddIcon,
  Close as CloseIcon,
  Edit as EditIcon,
} from '@material-ui/icons'

import { useMatchMedia } from '@Utils/hooks'

import EditFile from '@Components/icons/EditFile'

import { useAuth } from '@Context/auth'

export default function ChronologyEdit({
  folder,
  refreshChronology,
  chronology,
  setChronology,
  apiMode,
}) {
  const [showStepTypeModal, setShowStepTypeModal] = useState(false)
  const [stepSelected, setStepSelected] = useState(null)
  const [stepIcon, setStepIcon] = useState(null)
  const [showForm, setShowForm] = useState(true)
  const [stepsTypes, setStepsTypes] = useState([])
  const { watch, control, register, errors, handleSubmit, setValue, reset } =
    useForm()
  const { addSnackbarMsg } = useSnackbar()
  const watchStepType = watch('stepType')
  const [stepIsMeeting, setStepIsMeeting] = useState(false)
  const [dateErrorMsg, setDateErrorMsg] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [isCreating, setIsCreating] = useState(true)
  const [currentStepState, setCurrentStepState] = useState()

  const { user } = useAuth()

  const isMobile = useMatchMedia('(max-width: 900px)')

  useEffect(() => {
    if (isMobile) {
      setShowForm(false)
    }
  }, [isMobile])

  useEffect(() => {
    if (!isMobile && chronology?.steps?.length > 0) {
      setStepSelected(
        !stepSelected
          ? chronology.steps[0]
          : chronology.steps.find((s) => s.id === stepSelected.id) ||
              chronology.steps[0]
      )

      setStepIsMeeting(
        stepSelected
          ? stepSelected.stepType.isMeeting
          : chronology.steps[0].isMeeting
      )
    }
  }, [chronology])

  useEffect(() => {
    if (!stepSelected) {
      return
    }

    if (stepSelected.finishedAt !== null && !stepSelected.validatedAt) {
      setCurrentStepState('to_validate')
    } else if (stepSelected.validatedAt !== null) {
      setCurrentStepState('done')
    } else if (stepSelected.startedAt !== null) {
      setCurrentStepState('ongoing')
    } else {
      setCurrentStepState(null)
    }
  }, [stepSelected])

  useEffect(() => {
    async function fetchStepsTypes() {
      const {
        data: { results: stepsTypesArray },
      } = await ChronologiesAPI.getAllStepsTypes(apiMode)
      setStepsTypes(stepsTypesArray)
    }

    fetchStepsTypes()
  }, [apiMode])

  useEffect(() => {
    if (stepSelected) {
      setIsEditing(false)
      setIsCreating(false)
      setValue('title', stepSelected.title)
      setValue('subtitle', stepSelected.subtitle)
      setValue('content', stepSelected.content)
      setValue('note', stepSelected.note)
    } else {
      setIsEditing(true)
      setIsCreating(true)
    }
  }, [stepSelected, setValue])

  useEffect(() => {
    if (isEditing && stepSelected) {
      setValue('title', stepSelected.title)
      setValue('subtitle', stepSelected.subtitle)
      setValue('content', stepSelected.content)
      setValue('note', stepSelected.note)
    }
  }, [isEditing, setValue])

  useEffect(() => {
    console.log('create step')
    if (stepsTypes.length > 0) {
      const stepType =
        (!isEditing
          ? stepSelected?.stepType
          : stepsTypes.find(({ id }) => id === watchStepType)) || stepsTypes[0]
      setValue('stepType', stepType.id)
      if (typeof stepType?.isMeeting !== 'undefined') {
        setStepIsMeeting(stepType.isMeeting)
        setStepIcon(stepType.image)
      }
    }
  }, [
    watchStepType,
    stepsTypes,
    setValue,
    stepSelected,
    setStepIsMeeting,
    setStepIcon,
    isEditing,
    showForm,
  ])

  useEffect(() => {
    if (stepIsMeeting) {
      setValue('meetingLocation', stepSelected?.meetingLocation || null)
      setValue(
        'meetingDate',
        stepSelected?.meetingStartAt
          ? DateTime.fromISO(stepSelected.meetingStartAt)
          : null
      )
      setValue(
        'meetingStartAt',
        stepSelected?.meetingStartAt
          ? DateTime.fromISO(stepSelected.meetingStartAt)
          : null
      )
      setValue(
        'meetingEndAt',
        stepSelected?.meetingEndAt
          ? DateTime.fromISO(stepSelected.meetingEndAt)
          : null
      )
    }
  }, [stepIsMeeting, setValue, stepSelected])

  useEffect(() => {
    if (typeof watchStepType !== 'number') setStepIcon(null)
  }, [watchStepType, setStepIcon])

  const getIndexStep = (step) => {
    return 'steps' in chronology && 'id' in stepSelected
      ? chronology.steps.findIndex(({ id }) => step.id === id)
      : -1
  }

  const handleResetForm = () => {
    setStepSelected(null)
    setShowForm(true)
    reset()
  }

  const onSubmit = async (data) => {
    if (stepIsMeeting && !chronology.isModel) {
      if (
        data.meetingDate === null ||
        data.meetingStartAt === null ||
        data.meetingEndAt === null
      ) {
        setDateErrorMsg('La date est incomplète')
        return
      }

      if (data.meetingStartAt >= data.meetingEndAt) {
        setDateErrorMsg('La date de début doit précéder la date de fin')
        return
      }

      setDateErrorMsg(null)

      data.meetingStartAt = data.meetingDate
        .set({
          hour: data.meetingStartAt.hour,
          minute: data.meetingStartAt.minute,
        })
        .toISO()

      data.meetingEndAt = data.meetingDate
        .set({
          hour: data.meetingEndAt.hour,
          minute: data.meetingEndAt.minute,
        })
        .toISO()
    }

    delete data.meetingDate

    if (
      data.title.trim() === '' ||
      data.subtitle.trim() === '' ||
      data.content.trim() === ''
    ) {
      addSnackbarMsg({
        severity: 'error',
        content:
          "Les champs 'Titre', 'Sous titre' et 'Description' sont requis",
      })
      return
    }

    if (stepSelected !== null) {
      try {
        const { data: patchStep } = await ChronologiesAPI.patchStepChronologie(
          stepSelected.id,
          data,
          apiMode
        )
        const index = getIndexStep(stepSelected)
        const steps = chronology.steps
        steps.splice(index, 1, patchStep)
        setStepSelected(patchStep)
        setChronology({ ...chronology, steps })
        setIsEditing(false)
        addSnackbarMsg({
          severity: 'success',
          content: 'Étape modifiée',
        })
      } catch (error) {
        addSnackbarMsg({
          severity: 'error',
          content: getErrorMsgFromSymfonyResponse(error.response),
        })
      }
    } else {
      try {
        const { data: newStep } = await ChronologiesAPI.addStepChronologie(
          chronology.id,
          data,
          apiMode
        )
        setStepSelected(newStep)
        setChronology({
          ...chronology,
          steps: [...chronology.steps, newStep],
        })
        setIsEditing(false)
        addSnackbarMsg({
          severity: 'success',
          content: 'Étape ajoutée',
        })
      } catch (error) {
        addSnackbarMsg({
          severity: 'error',
          content: getErrorMsgFromSymfonyResponse(error.response),
        })
      }
    }
  }

  const onDrag = (id) => {
    const step = chronology.steps.find((s) => {
      return s.id === id
    })
    setStepSelected(step)
  }

  const onDelete = async () => {
    if (stepSelected !== null) {
      try {
        await ChronologiesAPI.deleteStepChronologie(stepSelected.id, apiMode)
        const index = getIndexStep(stepSelected)
        const steps = chronology.steps
        steps.splice(index, 1)
        setChronology({ ...chronology, steps })
        setStepSelected(null)
        addSnackbarMsg({
          severity: 'success',
          content: 'Étape supprimée',
        })
      } catch (error) {
        addSnackbarMsg({
          severity: 'error',
          content: getErrorMsgFromSymfonyResponse(error.response),
        })
      }
    }
  }

  const onAddStepType = (stepType) => {
    if (stepType) {
      setStepsTypes((current) => [stepType, ...current])
      setValue('stepType', stepType.id)
      addSnackbarMsg({
        severity: 'success',
        content: "Type d'étape ajoutée",
      })
    }
    setShowStepTypeModal(false)
  }

  const onCloseStepType = () => {
    setShowStepTypeModal(false)
  }

  const onUpdateStepState = async (validate = true) => {
    switch (currentStepState) {
      case 'ongoing':
        await ChronologiesAPI.patchFinishStep(stepSelected.id)
        refreshChronology()
        break

      case 'to_validate':
        if (validate) {
          await ChronologiesAPI.patchValidateStep(stepSelected.id)
        } else {
          await ChronologiesAPI.patchRefuseStep(stepSelected.id)
        }
        refreshChronology()
        break

      default:
        await ChronologiesAPI.patchStartStep(stepSelected.id)
        refreshChronology()
        break
    }
  }

  return (
    <main className="ChronologyEdit-container">
      {(!isMobile || (isMobile && !showForm)) && (
        <div className="ChronologyEdit-left">
          {apiMode !== 'customer' && (
            <div className="ChronologyEdit-leftHeader">
              <QMuiButton
                variant="outlined"
                className="isSecondary"
                isIcon
                onClick={() => {
                  setStepSelected(null)
                  setShowForm(true)
                  reset()
                }}
              >
                <AddIcon />
              </QMuiButton>
            </div>
          )}
          <ChronoTimeline
            chronology={chronology}
            stepSelected={stepSelected}
            onClickStep={(step) => {
              setShowForm(true)
              setStepSelected(step)
            }}
            apiMode={apiMode}
            onDragError={onDrag}
            onDragSuccess={onDrag}
          />
        </div>
      )}

      {(!isMobile || showForm || stepSelected !== null) && (
        <div
          className={classnames('ChronologyEdit-right', {
            isEditing,
            isCreating,
          })}
        >
          {isMobile && (
            <QMuiButton
              className="ChronologyEdit-backBtn"
              isIcon
              onClick={() => {
                setShowForm(false)
                setStepSelected(null)
              }}
            >
              <ArrowBackIcon />
            </QMuiButton>
          )}
          {currentStepState !== 'done' &&
            stepSelected !== null &&
            apiMode !== 'customer' && (
              <div className="ChronologyEdit-actions">
                <QMuiButton isIcon onClick={() => setIsEditing(!isEditing)}>
                  <EditIcon />
                </QMuiButton>

                <QMuiButtonConfirmation
                  confirmation="Êtes-vous sur de vouloir supprimer cette etape ?"
                  isIcon
                  handleConfirmation={() => onDelete()}
                >
                  <CloseIcon />
                </QMuiButtonConfirmation>
              </div>
            )}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="ChronologyEdit-formContainer"
          >
            <div className="ChronologyEdit-rightHeader">
              <div className="ChronologyEdit-infoFormContainer">
                <div className="ChronologyEdit-icon">
                  {stepIcon !== null ? (
                    <img
                      alt="Icon etape"
                      style={{ width: 50, height: 50 }}
                      src={stepIcon}
                    />
                  ) : (
                    <div style={{ width: 20, height: 20 }} />
                  )}
                </div>
                <div className="ChronologyEdit-infoForm">
                  <InputBase
                    placeholder="Titre"
                    name="title"
                    inputRef={register}
                    style={{ fontSize: '2rem' }}
                    className="ChronologyEdit-input"
                    disabled={!isEditing}
                  />
                  <InputBase
                    name="subtitle"
                    inputRef={register}
                    placeholder="Sous titre"
                    className="ChronologyEdit-input"
                    disabled={!isEditing}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="mt-2 flex align-items-center">
                <FormControl style={{ minWidth: '300px' }}>
                  <QSelect
                    label="Type d'étape"
                    name="stepType"
                    control={control}
                    defaultValue={stepSelected ? stepSelected?.stepType?.id : 1}
                    options={stepsTypes.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    isDisabled={!isEditing}
                  />
                </FormControl>
                {(isCreating || isEditing) && apiMode === 'admin' && (
                  <QMuiButton
                    variant="outlined"
                    className="ml-2"
                    isIcon
                    onClick={() => setShowStepTypeModal(true)}
                  >
                    <AddIcon />
                  </QMuiButton>
                )}
              </div>

              {stepIsMeeting && (
                <div className="ChronologyEdit-dates">
                  <QMuiFormInput
                    label="Lieu de rendez-vous"
                    name="meetingLocation"
                    register={register(
                      chronology.isModel ? {} : { ...formRules.isRequired }
                    )}
                    control={control}
                    errorMsg={errors?.meetingLocation?.message}
                    className="my-4"
                    containerStyle={{ width: '100%' }}
                    disabled={!isEditing || chronology.isModel}
                  />
                  <div className="flex flex-wrap">
                    <QMuiTimePicker
                      control={control}
                      name="meetingDate"
                      label="Date"
                      type="date"
                      disabled={!isEditing || chronology.isModel}
                      className="mr-4"
                    />
                    <div className="flex">
                      <QMuiTimePicker
                        control={control}
                        name="meetingStartAt"
                        label="Début"
                        type="time"
                        disabled={!isEditing || chronology.isModel}
                        className="mr-4"
                      />
                      <QMuiTimePicker
                        control={control}
                        name="meetingEndAt"
                        label="Fin"
                        disabled={!isEditing || chronology.isModel}
                        type="time"
                      />
                    </div>
                  </div>
                  {dateErrorMsg && (
                    <span className="input-error-msg mb-4">{dateErrorMsg}</span>
                  )}
                </div>
              )}
              {isEditing && (
                <InputBase
                  placeholder="Description"
                  name="content"
                  inputRef={register}
                  className="ChronologyEdit-textArea mt-5"
                  multiline
                  maxRows={5}
                />
              )}
              {!isEditing && (
                <div className="ChronologyEdit-content mt-5">
                  {stepSelected?.content}
                </div>
              )}
              {(isEditing || stepSelected?.note) && (
                <div className="ChronologyEdit-notes mt-4">
                  <span>Notes Qualy avocats&nbsp;:</span>
                  {isEditing && (
                    <InputBase
                      name="note"
                      inputRef={register}
                      className="ChronologyEdit-textArea mt-2"
                      multiline
                      maxRows={5}
                    />
                  )}
                  {!isEditing && (
                    <div className="ChronologyEdit-notes-content mt-2">
                      {stepSelected.note}
                    </div>
                  )}
                </div>
              )}
            </div>

            {!chronology.isModel && !isEditing && (
              <footer className="flex flex-col mt-auto mb-3 pt-3">
                <div className="flex justify-end">
                  {currentStepState === null && apiMode === 'expert' && (
                    <QMuiButtonConfirmation
                      btnText="Démarrer"
                      confirmation="Êtes-vous sûr de vouloir démarrer ?"
                      handleConfirmation={() => onUpdateStepState(true)}
                      className="ml-4"
                      size="small"
                      color="secondary"
                      variant="contained"
                    >
                      Démarrer
                    </QMuiButtonConfirmation>
                  )}
                  {currentStepState !== null && (
                    <div
                      className={
                        'MuiButtonBase-root MuiButton-root QMuiButton ' +
                        currentStepState
                      }
                    >
                      <span>
                        {
                          {
                            ongoing: 'En cours',
                            to_validate:
                              apiMode === 'customer'
                                ? 'En cours'
                                : folder?.referent.id === user.id
                                ? 'À valider'
                                : 'En attente de validation',
                            done: 'Terminé',
                          }[currentStepState]
                        }
                      </span>
                    </div>
                  )}

                  {currentStepState === 'ongoing' && apiMode !== 'customer' && (
                    <QMuiButtonConfirmation
                      btnText="Terminer"
                      confirmation="Êtes-vous sûr de vouloir terminer ?"
                      handleConfirmation={() => onUpdateStepState(true)}
                      className="ml-4"
                      size="small"
                      color="secondary"
                      variant="contained"
                    >
                      Terminer
                    </QMuiButtonConfirmation>
                  )}

                  {folder?.referent.id === user.id &&
                    'to_validate' === currentStepState && (
                      <>
                        <QMuiButtonConfirmation
                          btnText="Valider"
                          confirmation="Êtes-vous sûr de vouloir valider ?"
                          handleConfirmation={() => onUpdateStepState(true)}
                          className="ml-4"
                          size="small"
                          color="secondary"
                          variant="contained"
                        >
                          Valider
                        </QMuiButtonConfirmation>

                        <QMuiButtonConfirmation
                          btnText="Refuser"
                          confirmation="Êtes-vous sûr de vouloir refuser ?"
                          handleConfirmation={() => onUpdateStepState(false)}
                          className="ml-4"
                          size="small"
                          color="secondary"
                          variant="contained"
                        >
                          Refuser
                        </QMuiButtonConfirmation>
                      </>
                    )}
                </div>
              </footer>
            )}

            {isEditing && (
              <footer className="flex flex-col mt-auto mb-3 pt-3">
                <div className="flex justify-end mt-4">
                  <QMuiButtonConfirmation
                    btnText="Enregistrer"
                    confirmation="Êtes-vous sur de vouloir enregistrer ?"
                    color="primary"
                    type="submit"
                    variant="contained"
                  >
                    Enregistrer
                  </QMuiButtonConfirmation>
                </div>
              </footer>
            )}
          </form>
        </div>
      )}
      <StepTypeAddForm
        open={showStepTypeModal}
        onAdd={onAddStepType}
        onClose={onCloseStepType}
      />
    </main>
  )
}
