import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import QMsg from '@CommonComponents/QMsg'
import userAPI from '@API/user'
import { getErrorMsgFromSymfonyResponse } from '@Helpers'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'
import { InputAdornment } from '@material-ui/core'
import Password from '@Components/icons/Password'

export default function ResetPassword() {
  const token = new URLSearchParams(useLocation().search).get('token')

  const [successMsg, setSuccessMsg] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null)

  const { register, control, watch, handleSubmit } = useForm()

  const watchPassword = watch('password')

  const onSubmit = async ({ password }) => {
    setSuccessMsg(null) // Needed to reset the transition
    setErrorMsg(null)

    try {
      const resp = await userAPI.resetPassword(token, password)
      setSuccessMsg(resp.data.message)
    } catch (error) {
      setErrorMsg(getErrorMsgFromSymfonyResponse(error.response))
    }
  }

  const onError = (errors) => {
    setSuccessMsg(null)
    setErrorMsg(null)
    setErrorMsg(errors[Object.keys(errors)[0]].message)
  }

  return (
    <div>
      <h1 className="Login-title">Réinitialisatez votre mot de passe</h1>

      <form onSubmit={handleSubmit(onSubmit, onError)} className="Login-form">
        <QMuiFormInput
          containerStyle={{ width: '100%', marginBottom: 10 }}
          variant="loginVariant"
          register={register}
          control={control}
          name="password"
          type="password"
          placeholder="Nouveau mot de passe"
          startAdornment={
            <InputAdornment position="start">
              <Password width={20} />
            </InputAdornment>
          }
        />
        <QMuiFormInput
          containerStyle={{ width: '100%', marginBottom: 10 }}
          variant="loginVariant"
          register={register({
            required: 'Vous devez confirmer le mot de passe',
            validate: {
              sameAsPassword: (value) =>
                value === watchPassword
                  ? null
                  : 'Les deux mots de passes ne sont pas identique',
            },
          })}
          control={control}
          name="passwordConfirmation"
          type="password"
          placeholder="Répéter le mot de passe"
          startAdornment={
            <InputAdornment position="start">
              <Password width={20} />
            </InputAdornment>
          }
        />
        <Link to="/login" className="Login-forgotMdpLink">
          Connectez-vous
        </Link>
        <QMsg className="Login-validationMsg">{successMsg}</QMsg>
        <QMsg className="Login-validationMsg" variant="Danger">
          {errorMsg}
        </QMsg>
        <QMuiButton
          className="Login-submitBtn"
          type="submit"
          variant="outlined"
          color="white"
          size="large"
        >
          Réinitialiser mon mot de passe
        </QMuiButton>
      </form>

      <div className="Login-noAccount">
        <a
          href="https://www.qualy-avocats.fr/"
          target="_blank"
          rel="noreferrer"
          className="Login-forgotMdpLink"
        >
          <span>Vous n'avez pas de compte Qualy ?</span>

          <span>Faites nous votre demande juridique pour en obtenir un</span>
        </a>
      </div>
    </div>
  )
}
