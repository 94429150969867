import React from 'react'
import { useLocation } from 'react-router-dom'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import LoginForm from '@Components/forms/login/LoginForm'
import ForgottenPassword from '@Components/forms/login/ForgottenPassword'
import ResetPassword from '@Components/forms/login/ResetPassword'
import backgroundImg from '@Assets/img/login-adobestock.jpg'
import logo from '@Assets/img/logo-white.png'
import SvgWave from '@Components/icons/Wave'

import { useMatchMedia } from '@Utils/hooks'

export default function Login() {
  const action = new URLSearchParams(useLocation().search).get('action')

  const isMobile = useMatchMedia('(max-width: 700px)')

  return (
    <div>
      <div
        className="Login"
        style={isMobile ? { backgroundImage: `url(${backgroundImg})` } : {}}
      >
        <div className="Login-header">
          <img src={logo} alt="Logo" />
        </div>
        <div className="Login-left">
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={action}
              classNames="fade"
              addEndListener={(node, done) => {
                node.addEventListener('transitionend', done, false)
              }}
              timeout={10000}
            >
              <div className="Login-leftContainer">
                {(() => {
                  switch (action) {
                    case 'forgotten-password':
                      return <ForgottenPassword />
                    case 'reset-password':
                      return <ResetPassword />
                    default:
                      return <LoginForm />
                  }
                })()}
              </div>
            </CSSTransition>
          </SwitchTransition>
          <SvgWave className="Login-wave" />
          <SvgWave className="Login-waveShadow" />
        </div>
        <div
          className="Login-right"
          style={{ backgroundImage: `url(${backgroundImg})` }}
        />
      </div>
    </div>
  )
}
