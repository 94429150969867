import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import React, { useEffect, useState } from 'react'
import * as APIParameters from '@API/admin/parameters'
import ExpertsForm from '@Components/forms/folder/ExpertsForm'
import { InputBase } from '@material-ui/core'
import EditFile from '@Components/icons/EditFile'
import { useAuth } from '@Context/auth'
import { useForm } from 'react-hook-form'
import { patchProfile } from '@API/expert/expert'
import { useSnackbar } from '@Context/snackbar'
import QMuiButtonConfirmation from '@CommonComponents/Mui/QMuiButtonConfirmation'

export default function QualyTeam({ folder, refreshFolder, apiMode }) {
  const [isEditing, setIsEditing] = useState(false)
  const [usefulInformation, setUsefulInformation] = useState(null)
  const [submitForm, setSubmitForm] = useState(false)

  useEffect(() => {
    async function fetchInfo() {
      const { data = {} } = await APIParameters.getUsefulInformationList(
        apiMode
      )
      if (data.results.length > 0) {
        setUsefulInformation(data.results[0])
      }
    }
    fetchInfo()
  }, [apiMode])

  const onExpertFormSubmit = () => {
    setSubmitForm(true)
  }

  useEffect(() => {
    if (submitForm) {
      setSubmitForm(false)
    }
  }, [submitForm])

  const { user } = useAuth()

  const [isExpertSelfEdit, setIsExpertSelfEdit] = useState(false)

  return (
    <div>
      <header className="flex justify-end mb-4">
        {apiMode === 'admin' &&
          (isEditing ? (
            <>
              <QMuiButtonConfirmation
                className="mr-2"
                btnText="Enregistrer"
                confirmation="Êtes-vous sûr de vouloir enregistrer ?"
                handleConfirmation={onExpertFormSubmit}
              >
                Enregistrer
              </QMuiButtonConfirmation>
              <QMuiButton onClick={() => setIsEditing(false)} color="danger">
                Annuler
              </QMuiButton>
            </>
          ) : (
            <QMuiButton onClick={() => setIsEditing(true)}>Modifier</QMuiButton>
          ))}
      </header>

      <div className="QualyTeam-main">
        {isEditing ? (
          <ExpertsForm
            className="QualyTeam-lawyerForm"
            folder={folder}
            submitForm={submitForm}
            onDone={() => setIsEditing(false)}
            refreshFolder={refreshFolder}
          />
        ) : (
          <div className="QualyTeam-team">
            {folder?.referent && (
              <div className="QualyTeam-lawyer">
                <div className="QualyTeam-lawyerHead">
                  <div className="QualyTeam-lawyerHead-pic">
                    <img src={folder.referent.image} alt="Referent" />
                  </div>
                  <span>
                    {folder.referent.firstName} {folder.referent.lastName}
                  </span>
                </div>

                <div className="align-self-center flex-grow">
                  <div className="flex align-items-center">
                    <h3>Avocat référent</h3>

                    {apiMode === 'expert' &&
                      !isExpertSelfEdit &&
                      folder.referent.id === user.id && (
                        <QMuiButton
                          className="QualyTeam-presentationEditBtn"
                          isIcon
                          onClick={() => setIsExpertSelfEdit(true)}
                        >
                          <EditFile />
                        </QMuiButton>
                      )}
                  </div>

                  {apiMode === 'expert' &&
                  isExpertSelfEdit &&
                  folder.referent.id === user.id ? (
                    <ExpertPresentationEdit
                      expert={folder.referent}
                      refreshFolder={refreshFolder}
                      onDone={() => setIsExpertSelfEdit(false)}
                    />
                  ) : (
                    <p>{folder.referent.presentation}</p>
                  )}
                </div>
              </div>
            )}

            {folder?.experts.map((expert) => (
              <div key={expert.id} className="QualyTeam-lawyer">
                <div className="QualyTeam-lawyerHead">
                  <div className="QualyTeam-lawyerHead-pic">
                    <img src={expert.image} alt="Expert" />
                  </div>
                  <span>
                    {expert.firstName} {expert.lastName}
                  </span>
                </div>

                <div className="align-self-center flex-grow">
                  <div className="flex align-items-center">
                    <h3>Avocat expert</h3>

                    {apiMode === 'expert' &&
                      !isExpertSelfEdit &&
                      expert.id === user.id && (
                        <QMuiButton
                          className="QualyTeam-presentationEditBtn"
                          isIcon
                          onClick={() => setIsExpertSelfEdit(true)}
                        >
                          <EditFile />
                        </QMuiButton>
                      )}
                  </div>

                  {apiMode === 'expert' &&
                  isExpertSelfEdit &&
                  expert.id === user.id ? (
                    <ExpertPresentationEdit
                      expert={expert}
                      refreshFolder={refreshFolder}
                      onDone={() => setIsExpertSelfEdit(false)}
                    />
                  ) : (
                    <p>{expert.presentation}</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="QualyTeam-infos">
          <div>
            <header>
              <h2 className="QualyTeam-infosTitle">
                Information pratiques sur Qualy
              </h2>
            </header>

            {usefulInformation && (
              <div className="p-4">
                <h3 className="QualyTeam-infosSubtitle">
                  {usefulInformation.title}
                </h3>

                <p
                  dangerouslySetInnerHTML={{
                    __html: usefulInformation.content,
                  }}
                ></p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

function ExpertPresentationEdit({ expert, refreshFolder, onDone }) {
  const { register, handleSubmit } = useForm()

  const { addSnackbarMsg } = useSnackbar()

  const onSubmit = async (data) => {
    try {
      await patchProfile(data)
      refreshFolder()
      onDone()
    } catch (e) {
      addSnackbarMsg({
        severity: 'error',
        content: 'Echec de mise à jour du profile',
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputBase
        className="QualyTeam-presentationEdit"
        placeholder="Présentation"
        name="presentation"
        defaultValue={expert.presentation}
        multiline={true}
        inputRef={register}
        rows={9}
      />
      <div className="flex justify-end mt-2">
        <QMuiButtonConfirmation
          btnText="Enregistrer"
          confirmation="Êtes-vous sûr de vouloir enregistrer ?"
          type="submit"
          size="small"
        >
          Enregistrer
        </QMuiButtonConfirmation>
      </div>
    </form>
  )
}
