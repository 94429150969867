import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import QMsg from '@CommonComponents/QMsg'
import userAPI from '@API/user'
import { getErrorMsgFromSymfonyResponse } from '@Helpers'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'
import Email from '../../icons/Email'
import { InputAdornment } from '@material-ui/core'

export default function ForgottenPassword() {
  const [successMsg, setSuccessMsg] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null)

  const { register, control, handleSubmit } = useForm()

  const onSubmit = async ({ email }) => {
    setSuccessMsg(null) // Needed to reset the transition
    setErrorMsg(null)

    try {
      const resp = await userAPI.forgotPassword(email)
      setSuccessMsg(resp.data.message)
    } catch (error) {
      setErrorMsg(getErrorMsgFromSymfonyResponse(error.response))
    }
  }

  const onError = (errors) => {
    setSuccessMsg(null)
    setErrorMsg(null)
    setErrorMsg(errors[Object.keys(errors)[0]].message)
  }

  return (
    <div>
      <h1 className="Login-title">Mot de passe oublié</h1>

      <form onSubmit={handleSubmit(onSubmit, onError)} className="Login-form">
        <div>
          <QMuiFormInput
            containerStyle={{ width: '100%', marginBottom: 10 }}
            variant="loginVariant"
            register={register({ required: "L'email est requis" })}
            control={control}
            name="email"
            placeholder="Votre email"
            type="email"
            startAdornment={
              <InputAdornment position="start">
                <Email width={20} />
              </InputAdornment>
            }
          />
        </div>

        <Link to="/login" className="Login-forgotMdpLink">
          Connectez-vous
        </Link>

        <QMsg className="Login-validationMsg">{successMsg}</QMsg>

        <QMsg className="Login-validationMsg" variant="Danger">
          {errorMsg}
        </QMsg>

        <QMuiButton
          className="Login-submitBtn"
          type="submit"
          variant="outlined"
          color="white"
          size="large"
        >
          Réinitialiser mon mot de passe
        </QMuiButton>
      </form>

      <div className="Login-noAccount">
        <a
          href="https://www.qualy-avocats.fr/"
          target="_blank"
          rel="noreferrer"
          className="Login-forgotMdpLink"
        >
          <span>Vous n'avez pas de compte Qualy ?</span>

          <span>Faites nous votre demande juridique pour en obtenir un</span>
        </a>
      </div>
    </div>
  )
}
