import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import formRules from '@Utils/form-rules'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useSnackbar } from '@Context/snackbar'
import { getErrorMsgFromSymfonyResponse } from '@Helpers'
import logo from '@Assets/img/logo-white.png'
import FoldersAPI from '@API/admin/folders'

export default function CheckFolder() {
  const { control, errors, register, handleSubmit, setValue } = useForm({
    defaultValues: { readQuote: false, agreeQuote: false },
  })

  const { token } = useParams()

  const [folder, setFolder] = useState(null)
  const [successMsg, setSuccessMsg] = useState(null)

  const { addSnackbarMsg } = useSnackbar()

  useEffect(() => {
    async function fetchFolder() {
      const { data } = await FoldersAPI.getWithToken(token)
      setFolder(data)

      if (data.acceptedAt !== null) {
        setSuccessMsg('Le dossier a été validé')
      } else if (data.refusedAt !== null) {
        setSuccessMsg('Le dossier a été refusé')
      }
    }

    if (token) {
      fetchFolder()
    }
  }, [token])

  const onDownload = async () => {
    try {
      await FoldersAPI.downloadQuoteWithToken(token)
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  const onSubmit = async () => {
    try {
      await FoldersAPI.validateWithToken(token)
      setSuccessMsg(
        'Le dossier a été validé, vous allez recevoir prochainement un mail pour vous connecter à votre espace client'
      )
    } catch (error) {
      console.log(error)
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  const onRefuse = async () => {
    try {
      await FoldersAPI.refuseWithToken(token)
      setSuccessMsg('Le dossier a été refusé')
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  return (
    <div className="CheckFolder">
      <img className="CheckFolder-logo" src={logo} alt="Logo" />

      {folder && (
        <div className="CheckFolder-container">
          <h1>Nouveau dossier</h1>

          <h2>{folder.name}</h2>

          {successMsg ? (
            <p className="CheckFolder-successMsg">{successMsg}</p>
          ) : (
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col">
                  <QMuiButton
                    className="mb-5"
                    color="secondary"
                    onClick={onDownload}
                  >
                    Télécharger le devis
                  </QMuiButton>
                  <div className="flex flex-col">
                    <FormControlLabel
                      control={
                        <Switch
                          name="readQuote"
                          inputRef={register({ ...formRules.isRequired })}
                          control={control}
                          classes={{ root: 'QMuiSwitch' }}
                        />
                      }
                      label="J’ai lu l’intégralité du devis proposé par Qualy Avocats"
                    />
                    {errors?.readQuote?.type === 'required' && (
                      <span className="input-error-msg">
                        Veuillez valider cette condition
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <FormControlLabel
                      control={
                        <Switch
                          name="agreeQuote"
                          inputRef={register({ ...formRules.isRequired })}
                          control={control}
                          classes={{ root: 'QMuiSwitch' }}
                        />
                      }
                      label="En cochant cette case vous acceptez le devis proposé car Qualy avocats"
                    />
                    {errors?.agreeQuote?.type === 'required' && (
                      <span className="input-error-msg">
                        Veuillez valider cette condition
                      </span>
                    )}
                  </div>
                </div>

                <div className="CheckFolder-actions">
                  <QMuiButton color="danger" onClick={onRefuse}>
                    Refuser
                  </QMuiButton>

                  <QMuiButton className="ml-6" type="submit">
                    Valider
                  </QMuiButton>
                </div>
              </form>
            </>
          )}
        </div>
      )}
    </div>
  )
}
