import * as React from 'react'

function SvgWaveHeader(props) {
  return (
    <svg
      {...props}
      style={{ pointerEvents: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1920 125.217"
    >
      <defs>
        <linearGradient
          id="a"
          y1=".5"
          x2="1"
          y2=".5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#0378a0" />
          <stop offset="1" stopColor="#0a90be" />
        </linearGradient>
      </defs>
      <path
        dataname="Path 308"
        d="M.673.093h1922.478v122.524c-256.262 4.186-465.746 2.746-609.022.679-339.318-4.895-485.519-15.878-780.271-14.928C305.686 109.1 120.1 116.564.673 122.617"
        transform="translate(-.673 -.093)"
        fill="url(#a)"
      />
    </svg>
  )
}
export default SvgWaveHeader
